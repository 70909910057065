<template>
  <div class="formcontext"
       style="padding:30px; ">
    <dynamicForm :form="item"
                 v-for="(item,index) in formList"
                 :key="index"
                 :ref="'child'+index"
                 @submit="submit"></dynamicForm>
    <div>
      <el-button @click="back"
                 class="backbtn">返回</el-button>
      <el-button type="primary"
                 @click="submit">模拟提交</el-button>
    </div>
    <el-dialog v-model="dialogVisible">
      <img width="100%"
           :src="dialogImageUrl"
           alt="">
    </el-dialog>
  </div>
</template>

<script>
import dynamicForm from './lineComponent/dynamicForm.vue'
import { queryLineFormByLineId } from '@/api/config/config'
// import axios from 'axios'
// import {submitForm,queryById} from '@/api/config.js';
export default {
  components: {
    dynamicForm,
  },
  data() {
    return {
      defaultProps: {
        children: 'children',
        label: 'label',
      },
      list: {},
      formList: [],
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false,
      lineId: -1,
    }
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    logcascader(change) {
      console.log(change)
    },
    getForm() {
      queryLineFormByLineId(this.lineId).then((res) => {
        this.formList = res.data.map((e) => {
          let { formName, formContext, exampleUrl, modelUrl, required,description,ranking } = e
          formContext = JSON.parse(formContext)
          formContext[formContext.submitKey] = formContext.defaultVal
          if (
            formContext.type == 'cascader' &&
            formContext.dataType == 'custom'
          ) {
            for (let i = 0; i < formContext.options.length; i++) {
              this.setLeaf(formContext.options[i])
            }
            // debugger
          }
          description = description.replace(/\r|\n|\r\n/,'<br>');
          return {
            formName,
            configList: formContext,
            exampleUrl,
            modelUrl,
            required,
            description,
            ranking
          }
        })
        this.formList.sort((f,s)=>f.ranking - s.ranking)
        console.log(this.formList)
        // console.log(this.formList);
      })
    },
    submit(form) {
      let plist = []
      for (let i = 0; i < this.formList.length; i++) {
        // console.log(this.$refs[('child'+i)].submit());
        let p = new Promise((resolve) => {
          this.$refs['child' + i].submit((valid) => resolve(valid))
        })
        plist.push(p)
      }
      Promise.all(plist).then((arr) => {
        // console.log(arr)
        if (arr.filter((e) => !e).length == 0) {
          this.$notify.success('模拟通过')
        }
      })
      // console.log({form});
      // console.log({list:this.list});
      // console.log(this.list===form);
      // this.$refs['form'].validate(valid => {
      //   if (valid) {
      //     console.log(this.list);
      // this.$notify.success({
      //   title: 'Success',
      //   message: '校验通过',
      // })
      // const loading = this.$loading({
      //   mask: true,
      // })
      // let requestBody = {}
      // for (let item of this.list.configList) {
      //   requestBody[item.submitKey] = item[item.submitKey]
      // }
      /*  submitForm({formTemplateId:1,requestBody}).then(res=>{
        loading.close()
        if(res.code == 0){
          this.$notify.success({
            title:'Success',
            message:'提交成功'
          })
        }else{
          this.$notify.error({
            title:'Error',
            message:res.msg||'未知错误'
          })
        }
      }).catch(err=>{
        loading.close()
        this.$notify.error({
          title:'Error',
          message:err
        })
      }) */
      //   }
      // })
    },
    setLeaf(item) {
      if (item.children && item.children.length) {
        for (let i = 0; i < item.children.length; i++) {
          this.setLeaf(item.children[i])
        }
      } else {
        item.children = undefined
        item.leaf = true
        // debugger
      }
    },
  },
  mounted() {
    // if (this.$route.query.form) {
    //   this.list = JSON.parse(localStorage.getItem(this.$route.query.form))
    //   this.list.configList.map((e) => {
    //     if (e.type == 'cascader' && e.dataType == 'custom') {
    //       // console.log(e);
    //       for (let i = 0; i < e.options.length; i++) {
    //         this.setLeaf(e.options[i])
    //       }
    //     }
    //   })
    //   console.log(this.list)
    // }
    // axios.get('form/list').then(res=>{
    //   console.log(res);
    // }).catch(err=>{
    //   console.log(err);
    // })
    // queryFormList()
    // queryById({id:1}).then(res=>{
    //   if(res.code == 0){
    //     this.list = {formName:res.data.formName,configList:JSON.parse(res.data.formContext.replace(/\'/g,'\"'))}
    //   }
    // })
    // this.formList = [];
    // let list = JSON.parse(localStorage['onselectForm'])
    // this.formList = list.map((e) => {
    //   let res = JSON.parse(localStorage[e])
    //   res.formContext = JSON.parse(res.formContext)
    //   res.formContext.forEach(e=>{
    //     e[e.submitKey] = e.defaultVal;
    //   })
    //   return {
    //     formName: res.formName,
    //     configList:res.formContext,
    //   }
    // })
    // console.log(this.formList);
    this.lineId = this.$route.query.id || -1
    this.getForm()
  },
  created() {},
}
</script>

<style lang="scss" scoped>
.formcontext {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.imgcontext {
  width: 148px;
  height: 148px;
  position: relative;
  overflow: hidden;

  .upload {
    line-height: 148px;
    width: 148px;
    font-size: 30px;
    color: lightgrey;
  }

  &.bold {
    border: 1px dashed lightgrey;
    border-radius: 0.5rem;

    &:hover {
      .upload {
        color: deepskyblue;
      }

      border: 1px dashed deepskyblue;
    }
  }
}

.abshover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;

  &:hover {
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.3);
  }

  [class*='el-icon'] {
    color: #fff;
    cursor: pointer;
  }
}

.flex-center {
  display: flex;
  align-items: center;
}
</style>
